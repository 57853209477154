@use 'styles/globals/breakpoints';

.block{
    display: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    line-height: 1;
    margin-right: 2rem;
    margin-left: 0;
    width: 30px;
    height: 30px;

    @media screen and (max-width: breakpoints.$screen-sm){
        display: flex;
    }
}
