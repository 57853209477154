@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;

.block{
    min-height: 200px;

    @media screen and (max-width: breakpoints.$screen-sm){
        min-height: 162px;
    }
}

.cntr{
    @extend %flex-full-width;
    flex-direction: column;
    position: relative;
    height: 160px;

    & [class*="Chat_icon"]{
        width: auto;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0;   
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        height: 100px;
    }
}

.flyout{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-height: 100vh;
    background: #fff;
    flex-direction: column;
    z-index: 9999;
    overflow: auto;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    @media screen and (min-width: breakpoints.$screen-lg){
        max-width: 50vw;
        box-shadow:  var(--box-shadow-lg);
    }

    & [class*="Flyout_block"]{
        width: 100%;
        position: static;
        height: auto;
        box-shadow: none !important;
        top: 0
    }

    & [class*="Flyout_item"]{
        background-color: #fff;
        padding: 0 0 0.25rem 0;
    }

    & [class*="Flyout_listLink"]{
        padding: 1.5rem 2rem;
        color: #000;
    }
}

.close{
    display: flex;
    padding: 1rem;
    background-color: #fff;
    flex: 0;
    max-height: 60px;
    width: 100%;
    justify-content: flex-end;
    flex: 0;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        max-height: 75px;    
    }

    & svg{
        height: 40px;
        width: 40px;
    }

}


.auxCntr{
    @extend %flex-full-width;
    flex-direction: row;
    padding: 1rem var(--header-padding 0 --header-padding);

    @media screen and (max-width: breakpoints.$screen-sm){
        display: none;
    }
}

.wrapper{
    @extend %flex-full-width;
    flex-direction: column;
    max-width: var(--standard-width);
}


// center main body
.bodyWrapper{
    @extend %flex-full-width;
    flex-direction: column;
    height: 125px;
    justify-content: flex-end;
    flex: 1 0 auto;

    @media screen and (max-width: breakpoints.$screen-xl){
        height: auto;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        height: 99px;
    }
}

.bodyCntr{
    @extend %flex-full-width;
    flex-direction: column;
    width: 100vw;
    background: #ffff;

    &__fixed{
        left: 0;
        z-index: 999;
        box-shadow: 3px 9px 11px 0px rgba(0, 0, 0, 0.10);
        padding: 0 1rem;

        @media screen and (max-width: breakpoints.$screen-sm) {
            & [class*="HeaderSearch_block"]{
                padding: 0
            }
        }

        & [class*="Header_body"]{
            justify-content: space-between;
            width: 100%;
        }
    }
}

.body{
    @extend %flex-full-width;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 1rem var(--header-padding);
    justify-content: space-between;

    @media screen and (max-width: breakpoints.$screen-lg) {
        justify-content: flex-start;
        padding: 1rem 2rem;
    }
    
    @media screen and (max-width: breakpoints.$screen-md) {
        justify-content: center;
    }

    @media screen and (max-width: breakpoints.$screen-sm) {
        padding: 1rem 0rem;
    }
}

.wrap{
    flex-wrap: wrap;
}

.logoBlock{
    display: flex;
    align-self: center;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    
    @media screen and (max-width: breakpoints.$screen-lg) {
        margin-right: 2rem;
    }
}


// utils
.bar{
    display: flex;
    align-self: center;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 100%;
    
    @media screen and (max-width: breakpoints.$screen-sm){
        // width: auto;
        order: 2;
    }

    &__logoChat{
        display: flex;

        @media screen and (max-width: breakpoints.$screen-xs) and (orientation: portrait) {
            display: none;
        }
    }
}



.top{
    display: flex;
    width: 100%;
    padding: 0 4vw;
    align-items: center;

    @media screen and (min-width: breakpoints.$screen-xl){
        padding: 0 2vw;
    }
}


.mobileBar{
    @media screen and (min-width: breakpoints.$screen-md){
        width: 100%;

        // & [class*="HeaderLogo_logo"]{
        //     width: 25%;
        // }

        & + [class*="HeaderSearch_block"]{
            padding: 1rem 0 0 0;
        }
    }
}


.barLandscape{
    width: auto;
    margin-left: 2rem;
}

.immobileAux{
    order: 3;
    display: flex;
    margin-left: 2rem;
}

.topImmobile{
    padding-right: 2rem;
    width: auto;
}

.tradeBanner{
    font-size: 1.6rem;
    line-height: 1.3;
    color: var(--indigo);
    border-radius: 0;
    margin: 0;
}
